<template>
  <v-container v-if="loaded">
    <p v-if="countryExists == false">{{ $t('contact_payout_no_country') }}</p>

    <div v-if="countryExists == true">
      <!-- <v-card-title class="px-0 mx-0 pt-0">
        Cashout
        <v-spacer></v-spacer>
        <v-btn color="primary" v-on:click="$router.go(-1)">Back</v-btn>
      </v-card-title> -->

      <div v-if="$route.params && $route.params.cashoutBankAvailable == true">
        <div v-if="errorType == 1">
          <p>{{ $t('no_cashout_info') }}</p>
        </div>
        <div v-else-if="countryExists==true">
          <p v-if="hasMetRedemptionCondition() == false">
            {{ $t('points_to_redeem_min', { 0: countrySpecificInfo().minToRedeem } ) }}
          </p>
          <v-stepper v-model="e6" vertical transparent v-else>
            <v-stepper-step :complete="e6 > 0" step="0">
              {{ $t('frequently_asked_questions')}} / {{ $t('terms_conditions') }}
            </v-stepper-step>

            <v-stepper-content step="0">
              <p class="pa-0 ma-0 font-weight-medium">{{ $t('min_req_redemption') }}</p>
              <p>{{$t('min_req_redemption_ans')}} {{ countrySpecificInfo().minToRedeem }}.</p>

              <p class="pa-0 ma-0 font-weight-medium">{{$t('points_currency_ratio')}}</p>
              <p>{{ cashOutDetails.minToRedeem }} {{ $t('points_currency_ratio_ans') }} {{ cashOutDetails.currency }} {{ Math.floor(cashOutDetails.valuePerPoint * cashOutDetails.minToRedeem) }}</p>

              <p class="pa-0 ma-0 font-weight-medium">{{$t('reward_expect')}}</p>
              <p>{{ $t('reward_expect_ans') }} </p>

              <p class="pa-0 ma-0 font-weight-medium">{{$t('contact_payout')}}</p>
              <p>{{ $t('contact_payout_ans') }} </p>

              <p>{{ $t('by_clicking') }}<span class="font-weight-bold">{{ $t('continue') }}</span>, {{ $t('you_have_read') }} {{ $t('frequently_asked_questions') }} / {{ $t('terms_conditions') }}.</p>

              <v-btn color="primary" @click="e6 = 1">{{ $t('continue') }}</v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 1" step="1">
            {{ $t('bank_account_details') }}
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-form ref="form" v-if="loaded && hasMetRedemptionCondition()">
                <p>{{ $t('provide_bank_account_details') }}</p>
                <BankDetailsForm
                  :bankDetails="bankDetails"
                />
              </v-form>

              <v-btn color="primary" @click="updateDetails()">{{ $t('continue') }}</v-btn>
              <v-btn @click="e6 = 0" text>Back</v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 2" step="2">{{ $t('choose_cashout_amount') }}</v-stepper-step>

            <v-stepper-content step="2">
              <PointStepper 
                :cashOutDetails="cashOutDetails"
              />

              <v-btn color="primary" @click="e6 = 3">{{ $t('continue') }}</v-btn>
              <v-btn @click="e6 = 1" text>{{ $t('back') }}</v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 3" step="3">{{ $t('confirm_selections') }}</v-stepper-step>

            <v-stepper-content step="3">
              <v-form ref="form">
                <p>{{$t('confirm_details')}}</p>
                <BankDetailsForm
                  :bankDetails="bankDetails"
                  :readonly="true"
                />

                <v-text-field
                  :label="$t('points_to_redeem')"
                  v-model="cashOutDetails.points"
                  readonly
                />

                <v-text-field
                  :prefix="cashOutDetails.currency"
                  :label="$t('cashout_amount')"
                  v-model="cashOutDetails.amountInCurrency"
                  readonly
                />

                <v-btn color="primary" @click="rewardCashoutBank()">{{ $t('confirm') }}</v-btn>
                <v-btn @click="e6 = 2" text>{{ $t('back') }}</v-btn>
              </v-form>
            </v-stepper-content>

            <v-stepper-step step="4">{{$t('thank_you')}}</v-stepper-step>
            <v-stepper-content step="4">
              <p>{{ $t('points_confirmation_email', { 0: cashOutDetails.points } ) }}</p>
            </v-stepper-content>
          </v-stepper>
        </div>
      </div>
      <div v-else>
        <p v-html="$t('cashout_bank_not_available_reasons')"></p>
      </div>
    </div>
  </v-container>
</template>

<script>
import { DataService } from "../../services/DataService";
import BankDetailsForm from "../BankAccount/form.vue"
import PointStepper from "../BankAccount/pointStepper.vue"

const dataService = new DataService();
export default {
  components: {
    BankDetailsForm, PointStepper
  },
  data() {
    return {
      e6: 0,
      errorType: null,
      loaded: false,
      loading: false,
      bankDetails: {},
      cashOutDetails: {
        points: 0,
        minToRedeem: 0,
        pointsPerUnit: 0,
        amountInCurrency: 0,
        currency: null,
      },
      countryConversion: {},
      countryExists: true,
      hasRedeemed: false
    }
  },

  watch: {
    'cashOutDetails.points': function(){      
      let pointConversion = this.cashOutDetails.minToRedeem + (this.cashOutDetails.multiplier * this.cashOutDetails.multiplierPoints)
      this.cashOutDetails.amountInCurrency = Math.floor(pointConversion * this.cashOutDetails.valuePerPoint)
    }
  },

  async mounted() {
    this.$store.state.loader = true
    await this.getConversionData()

    let country = this.$store.state.userProfile.demographics.find(demographic => demographic.title == "Country")
    this.bankDetails.country = country.answer
        
    if (this.$store.state.userProfile.bankDetails && Object.keys(this.$store.state.userProfile.bankDetails).length > 0 && this.$store.state.userProfile.bankDetails.country == this.bankDetails.country) {
      this.bankDetails = this.$store.state.userProfile.bankDetails
    } else { 
      this.bankDetails.name = this.$store.state.userProfile.name
    }
    this.hasConversionInfo()
    this.initCashOutDetails()
    this.checkCountryExists()
    this.$store.state.loader = false
    this.loaded = true
  },

  methods: {
    checkCountryExists() {
      let country  = this.countryConversion[this.bankDetails.country]
      if (country == undefined || country == null) {
        this.countryExists = false
      }
    },
    initCashOutDetails() {
      this.cashOutDetails = Object.assign(this.cashOutDetails, this.countrySpecificInfo())
      this.cashOutDetails.points = this.cashOutDetails.minToRedeem
      this.cashOutDetails.totalPoints = this.$store.state.userProfile.totalPoints
      this.cashOutDetails.multiplier = 0
    },
    countrySpecificInfo() {
      let countryConversion = this.countryConversion[this.bankDetails.country]
      if (countryConversion == undefined || countryConversion == null) {
        return {}
      } else {        
        return countryConversion
      }
    },
    hasConversionInfo() {
      if (Object.keys(this.countrySpecificInfo()) > 0) {
        this.errorType = "1"
      }
    },
    hasMetRedemptionCondition() {             
      return this.$store.state.userProfile.totalPoints >= (this.countrySpecificInfo().minToRedeem) || this.hasRedeemed == true ? true : false
    },
    updateDetails() {
      if (this.$refs.form.validate()) {
        let data = {
          userId: this.$store.state.userProfile._id,
          bankDetails: this.bankDetails,
        }
        dataService.updateMobileUser(this.$store.state.token, data)
        this.e6 = 2
      }
    },
    async getConversionData() {
      let data = await dataService.getConfig(`countryCashoutConversion`)
      this.countryConversion = data
    },
    rewardCashoutBank() {
      this.$store.state.loader = true
      dataService.rewardCashoutBank(this.$store.state.token, { cashoutData: { ...this.bankDetails, ...this.cashOutDetails } }).then(res => {
        if (res.error != true) {
          this.hasRedeemed = true
          this.e6 = 4
          this.$store.state.loader = false
          this.$store.state.userProfile = res.userProfile
        } else {
          if (res.redirect) {
            this.$router.push({ name: res.redirect, params: { deactivationMessage: true } })
          }

          this.$store.state.loader = false
          this.$store.state.snackBarText = this.$t(res.message)
          this.$store.state.showSnackBar = true
        }
      })
    }
  }
}
</script>